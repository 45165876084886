.Main {
  .First {
    margin: 32px 16px 0 16px;
    padding: 24px;
    background: #FFFFFF;
    box-shadow: 0px 2px 16px rgba(43, 60, 72, 0.1);
    border-radius: 8px;
    .Title {
      display: flex;
      justify-content: center;
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 16px;
    }
    .Content01 {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.5);
      text-indent:30px;
      font-family: PingFang SC;
      line-height: 20px;
    }
    .Content02 {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.5);
      text-indent:30px;
      font-family: PingFang SC;
      line-height: 20px;
      margin-top: 8px;
    }
    .Btn {
      margin-top: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      left: 0.5px;
      top: 306px;

      background: #44A0F4;
      border-radius: 24px;
      color: #FFFFFF;
      font-weight: bold;
    }
  }

}
