.UnSelected {
  height: 40px;
  width: 40px;
  border:1px solid grey;
  border-radius: 5px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  //font-weight: bold;
  font-size: 20px;
}

.Selected {
  height: 40px;
  width: 40px;
  border:1px solid green;
  border-radius: 5px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  //font-weight: bold;
  font-size: 20px;
}
