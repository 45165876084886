.Main {

}

.DeptHeader {
  //width: 300px;
  //height: 50px;
  //background: black;
  padding: 10px;

  .DeptHeaderBtn {
    //width: 100%;
    display: flex;
    margin-top: 10px;

    .DeptHeaderBtnConfirm {
      margin-left: auto;
    }
  }
}
