.Main {
  margin-bottom: 10px;
  .Label {
    font-family: 'PingFang SC';
    font-size: 14px;
    color: rgba(0, 0, 0, 0.3);
  }
  .Value {
    font-family: 'PingFang SC';
    font-weight: bold;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.9);
    margin-top: 5px;
  }

}
