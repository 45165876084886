.Main {
  .Input {
    background: white;
    margin: 10px 20px;
    padding: 5px;
    border-radius: 4px;
  }
  .Tab {
    overflow-y: scroll;
    height: 100%;
    .TabHeader {
      background: white;
    }
  }
}
