.Main {
  height: 40px;

  background: #3B74C5;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-size: 14px;
    color: white;
  }
}
