.Main {
  height: 100%;
  background: #E5E5E5;

  .First {
    height: 208px;
    background: #409EFF;
  }

  .Second {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 16px 16px 24px;
    margin: 0 16px;
    margin-top: -180px;

    .Desc {
      background: rgba(225, 242, 217, 0.5);
      border-radius: 4px;
      padding: 6px 16px;
      font-family: 'PingFang SC';
      font-size: 14px;
      text-align: justify;

      color: #67C23A;
    }

    .Icon {
      display: flex;
      justify-content: center;
      margin: 24px 0;
    }

    .Notice {
      background: rgba(255, 245, 228, 0.5);
      border-radius: 8px;
      padding: 8px 16px;
      font-family: 'PingFang SC';
      font-size: 14px;
      text-align: justify;

      color: rgba(0, 0, 0, 0.5);

    }

    .Info {
      padding: 20px;
    }
  }

}
