.Main {
  background: white;

  .Content {
    padding: 15px;

    .First {
      display: flex;
      align-items: center;
      margin: 7px 0;

      .Title {
        font-size: 14px;
        color: black;
        margin-right: 10px;
        font-weight: bold;
      }

      .Tag {

      }

      .CreateTime {
        margin-left: auto;
        color: rgba(0, 0, 0, 0.3);
        font-size: 14px;
      }
    }
  }
}
