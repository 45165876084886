.Main {
  width: 100%;
  height: 100%;
  .QueryPanel {
    background: white;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.5);
    .QueryPanelItem {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      line-height: 100%;
      span {
        margin-right: 5px;
      }
    }

  }
  .Empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    .EmptyImg {
      margin-top: 130px;
      width: 60px;
      height: 60px;
      background: linear-gradient(180deg, rgba(75, 128, 203, 0.01) 0%, rgba(74, 128, 203, 0.1) 100%);
    }
    .EmptyTip {
      margin-top: 40px;
      color: rgba(0, 0, 0, 0.3);
      font-size: 14px;
    }
  }
}
