.Main {
  display: flex;
  align-items: center;
  .PointContainer {
    display: flex;
    align-items: center;
    .Point {
      height: 10px;
      width: 10px;
      border-radius: 10px;
      background: grey;
      margin-right: 5px;
    }
  }

}
