.Main {
  .AppointmentDate {
    display: flex;
    align-items: center;
    line-height: 150%;
    //background: black;
    min-height: 30px;


    .Date {
      padding: 0 !important;
      margin: 0 !important;
      min-width: 80px;
      min-height: 30px;
      display: flex;
      align-items: center;
      //background: green;
    }

    .EmptyStartDate {
      padding: 0 !important;
      margin: 0 !important;
      min-width: 100%;
      min-height: 30px;
      color: #cccccc;
    }

    .Separator {
      margin: 0 5px;
    }
  }

}
