.Main {
  height: 100%;
  width: 100%;
  //overflow-y: scroll;

  background: #F2F5FD;
  .Tab {
    overflow-y: scroll;
    height: 100%;
    .TabHeader {
      background: white;
    }
  }
}
