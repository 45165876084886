.Main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .Banner {
    width: 100%;
    img {
      width: 100%;
      border-radius: 0 0 15px 15px;
    }
  }

  .First {
    position: absolute;
    height: 60px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    top: 40px;

    .Logout {
      margin-left: auto;
      display: flex;
      align-items: center;
      height: 30px;
      border-radius: 3px;

      p {
        font-size: 16px;
        color: #409EFF;
        margin-left: 5px;
      }
    }

    .FirstRight {
      margin-left: 10px;
      color: #FFFFFF;
      .Hello {
        font-family: 'Microsoft YaHei';
        font-size: 18px;
        line-height: 20px;
      }

      .Info {
        display: flex;
        align-items: flex-end;
        margin-top: 15px;
        line-height: 1;
        .Name {
          font-family: 'Microsoft YaHei';
          font-weight: bold;
          font-size: 24px;
        }
        .Number{
          font-family: 'Source Sans Pro';
          font-size: 14px;
          margin-left: 4px;
        }
      }
    }
  }

  .Second {
    margin-top: 20px;
  }

  .Bottom {
    margin-top: auto;
    .BottomSecond {
      display: flex;
      justify-content: center;
      font-size: 16px;
      color: #C0C4CC;
      margin-bottom: 30px;
    }

  }

}
