.Main {
  margin-top: 5px;

  .Process {
    padding: 0 !important;
    margin: 0 !important;
  }

  .First {
    display: flex;
    align-items: center;
    line-height: 150%;
    font-size: 14px;
    .Tag {
    }
    p {
      display: flex;
      align-items: center;
      color: #909399;
      margin-left: 5px;
      min-width: 115px;

    }


  }

  .Second {
    width: 180px;
    word-break: break-all;
    font-size: 14px;
    line-height: 26px;
    color: #303133;
  }
}
