html, body {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  max-width: 500px;
  max-height: 1000px;
  box-shadow: 0px 2px 16px rgba(43, 60, 72, 0.1);
  background: #F2F5FD;
  font-family: PingFang SC, Lantinghei SC, Helvetica Neue, Helvetica, Arial, Microsoft YaHei, 微软雅黑, STHeitiSC-Light, simsun, 宋体, WenQuanYi Zen Hei, WenQuanYi Micro Hei, sans-serif;
  font-size: 12px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

p {
  margin: 0;
  padding: 0;
}

/*隐藏滚动条*/
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

/*.i-icon {*/
/*  display: flex;*/
/*  align-items: center;*/
/*}*/

.adm-list-body {
  border-top: none !important;
  border-bottom: none !important;
  background-color: transparent !important;
}
.adm-infinite-scroll span {
  display: none;
}