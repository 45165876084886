.Main {
  width: 100%;
  display: flex;
  flex-direction: column;

  .Content {
    .Submit {
      background-color: #3B74C5;
      color: white;
      font-size: 14px;
      line-height: 20px;
    }

    .Common {
      background: white;
      border-radius: 8px;
      margin-top: 10px;
      .CommonTitle {
        font-weight: bold;
        font-size: 14px;
        line-height: 40px;
        color: rgba(0, 0, 0, 0.9);
        margin-left: 15px;
        display: flex;
        span {
          margin-left: auto;
          margin-right: 10px;
        }
      }

      .PlaceHolder {
        display: flex;
        color: #cccccc;
        margin-left: 15px;
        .Icon {
          color: #cccccc;
          margin-left: auto;
        }
      }
      .Value {
        display: flex;
        line-height: 150%;
        .Icon {
          color: #cccccc;
          margin-left: auto;
        }
      }
    }
  }

}
