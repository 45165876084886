.Main {
  .ItemHeader {
    height: 30px;
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 15px;
    .Left {
      color: #606266;
      margin-left: 6px;

    }
    .Right {
      height: 30px;
      margin-left: auto;
      display: flex;
      align-items: center;
      color: #409EFF;
      .RightContent {
        margin-left: 10px;
      }
    }

  }

  .Attachment {
    .P1 {
      display: flex;
      align-items: center;
      p {
        font-size: 12px;
        color: #909399;
      }


    }
    .P2 {
      margin-top: 5px;
      font-size: 12px;
      color: #909399;
    }
  }
}


