.Main {
  width: 100%;
  height: 100%;
  position: relative;
  .MainContent {
    width: 100%;
    height: calc(100% - 100px);
    overflow-y: scroll;
    .QueryPanel {
      background: white;
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.5);
      .QueryPanelItem {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        span {
          margin-right: 5px;
        }
      }

    }
    .Empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      .EmptyImg {
        margin-top: 130px;
        width: 60px;
        height: 60px;
        background: linear-gradient(180deg, rgba(75, 128, 203, 0.01) 0%, rgba(74, 128, 203, 0.1) 100%);
      }
      .EmptyTip {
        margin-top: 40px;
        color: rgba(0, 0, 0, 0.3);
        font-size: 14px;
      }
    }

    .CheckboxItem {
      width: 100%;
      background: white;
      padding-left: 10px;
    }
  }


  .Operation {
    height: 100px;
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    background: white;

    .Comment {
      background: rgba(0, 0, 0, 0.05);
      margin: 10px 20px;
      padding: 5px 10px;
      width: auto;
      border-radius: 4px;
    }

    .Btn {
      margin: 0 20px;
      margin-top: 15px;
      display: flex;
      align-items: center;

      .BtnItem {
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        width: 164px;
        height: 26px;
        font-size: 14px;
        color: #F11010;
      }

      .BtnItem02 {
        margin-left: auto;
        color: #3B74C5;
      }

    }
  }
}
