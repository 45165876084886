.Main {
  min-height: 60vh;

  .Total {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.3);
    .Tip {
      font-size: 12px;
    }
  }

  .Content {
    margin: 10px 14px 0;
  }

}
