.Main {
  display: flex;
  margin-top: 8px;
  font-size: 14px;

  .ItemLeft {
    p {
      width: 110px;
      color: #909399;
      padding: 0 5px;
    }
  }

  .ItemRight {
    padding-top: 3px;
    word-break: break-all;
  }
}
