.Main {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #E5E5E5;
  .First {
    display: flex;
    justify-content: center;
    margin: 40px 0;
  }

  .Second {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Microsoft YaHei';
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
  }

  .Bottom {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: 140px;
    .Left {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 112px;
      height: 32px;
      border: 1px solid rgba(0, 0, 0, 0.3);
      box-sizing: border-box;
      border-radius: 2px;

      font-family: 'Microsoft YaHei';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: center;

      color: rgba(0, 0, 0, 0.9);
    }
    .Right {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 112px;
      height: 32px;
      border: 1px solid #58B2DC;
      box-sizing: border-box;
      border-radius: 2px;

      font-family: 'Microsoft YaHei';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      /* identical to box height */

      text-align: center;

      color: #58B2DC;
      margin-left: 16px;
    }
  }

}
