.Main {
  height: 50px;
  width: 50px;
  border:1px solid grey;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  //font-weight: bold;
  font-size: 16px;
}
