.Main {
  width: 100%;
  height: 100%;
  position: relative;

  .Detail {
    overflow-y: scroll;
    height: calc(100% - 120px);

    .First {
      display: flex;
      align-items: center;
      margin: 10px 0;
      padding: 10px 20px;
      background: white;

      .Left {
        display: flex;
        align-items: center;
        .Icon {
          width: 15px;
          height: 15px;
          background: orange;
          border-radius: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 2px;
          span {
            font-family: Noto Sans SC;
            font-size: 8px;
            color: white;
          }
        }
      }

      .RightIcon {
        margin-left: auto;
      }


    }

    .Second {
      display: flex;
      align-items: center;
      margin: 10px 0;
      padding: 10px 20px;
      background: white;

      .Title {
        font-size: 14px;
        color: black;
        font-weight: bold;
        margin: 0 10px;
      }

      .Tag {

      }
    }

    .Content {
      background: white;
      padding: 20px;
      margin-bottom: 10px;
    }

    .Process {
      background: white;
      padding: 20px;
    }
  }

  .Operation {
    height: 100px;
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    background: white;

    .Comment {
      background: rgba(0, 0, 0, 0.05);
      margin: 10px 20px;
      padding: 5px 10px;
      width: auto;
      border-radius: 4px;
    }

    .Btn {
      margin: 0 20px;
      margin-top: 15px;
      display: flex;
      align-items: center;

      .BtnItem {
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        width: 164px;
        height: 26px;
        font-size: 14px;
        color: #F11010;
      }

      .BtnItem02 {
        margin-left: auto;
        color: #3B74C5;
      }

    }
  }

}
