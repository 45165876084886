.Main {
  margin-top: 5px;
  //background: green;
  .Head{
    display: flex;
    align-items: center;
    line-height: 150%;
    font-size: 12px;
    color: black;
    .Title {
      margin: 0 5px;
    }
    .Point {
      height: 3px;
      width: 3px;
      background: black;
      border-radius: 15px;
      margin: 0 5px;
    }
    .Metting {
      margin-left: 5px;
      padding: 2px 3px;
    }
  }
  .Content {
    display: flex;
    .ProcessBar {
      min-width: 2px;
      margin-left: 7px;
    }
    .Peoples {
      flex: 1;
    }
  }


}
