.Main {
  display: flex;
  margin-top: 15px;
  align-items: center;
  .Left {
    display: flex;
    align-items: center;
    min-width: 100px;
    .AvatarItem {
      position: relative;
      .AvatarItemIcon {
        width: 15px;
        height: 15px;
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(50%, -50%);
      }

      .Avatar {
        margin-left: 20px;
        height: 30px;
        width: 30px;
        //border-radius: 15px;
      }
    }
    .Name {
      font-size: 12px;
      margin-left: 10px;
    }
  }
  .Comment {
    font-size: 12px;
    margin-left: 10px;
    word-break: break-all;
    span {
      color: black;
    }
  }
  .ApprovalTime {
    margin-left: auto;
    display: flex;
    align-items: center;
    line-height: 150%;
    .Point {
      height: 3px;
      width: 3px;
      background: #999999;
      border-radius: 15px;
      margin: 0 5px;
    }
  }
}
