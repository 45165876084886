.Main {
  display: flex;
  align-items: center;
  height: 100%;
  min-width: 280px;

  .Right {
    width: 100%;

    .Name {
      font-weight: bold;
      font-size: 18px;
    }

    .Second {
      display: flex;
      color: #cccccc;
      margin-top: 3px;

      .Phone {

      }

      .IdentityCardNumber {
        margin-left: auto;
      }
    }
  }


}
