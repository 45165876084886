.Main {

}

.Container {
  padding: 20px;

  .First {
    margin-bottom: 20px;
  }

  .Second {
    margin-bottom: 20px;
  }


  .Bottom {
    display: flex;

    .BottomRight {
      margin-left: auto;
      margin-right: 10px;
    }
  }
}
