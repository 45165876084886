.Main {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
  line-height: 100%;

  .ItemLeft {
    display: flex;
    align-items: center;
    width: 110px;
    color: #909399;
    padding: 0 5px;
  }

  .ItemRight {
    display: flex;
    align-items: center;
    word-break: break-all;
  }
}
