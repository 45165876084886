.Main {
  .Header {
    display: flex;
    margin-top: 8px;
    font-size: 16px;
    align-items: center;

    .Icon {
      width: 17px;
      height: 17px;
      background: #277EF2;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-family: Noto Sans SC;
        font-size: 12px;
        color: white;
      }
    }

    .ItemLeft {
      p {
        width: 100px;
        //color: #909399;
        padding: 0 5px;
      }
    }
  }
  .Content {
    background: white;
    padding: 15px;
    margin-bottom: 10px;
  }
}
