.Main {
  background: white;
  border-radius: 8px;
  display: flex;
  padding: 24px;
  margin: 0 22px 24px;

  .Left {
    .Title {
      font-family: 'PingFang SC';
      font-size: 18px;
      color: rgba(0, 0, 0, 0.9);
    }

    .Desc {
      font-family: 'PingFang SC';
      font-size: 14px;
      line-height: 150%;
      color: rgba(0, 0, 0, 0.5);
      margin-top: 3px;
    }
  }

  .Icon {
    margin-left: auto;
    height: 50px;
  }

}
